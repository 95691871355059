/** @jsx jsx */
import { jsx, Image } from 'theme-ui';
import { Link } from 'components/link';
import logo from 'assets/images/logo.png';

export default function Logo(props) {
  return (
    <Link path="/" sx={styles.logo} {...props}>
      <Image src={logo} alt="logo" />
    </Link>
  );
}
const styles = {
  logo: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'inline-flex',
    img: {
      height: '40px',
      width: "100%",
    },
  },
};
