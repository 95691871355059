import facebook from 'assets/images/icons/facebook.png';
import twitter from 'assets/images/icons/twitter.png';
import github from 'assets/images/icons/github.png';

export const menuItems = [
  {
    id: 1,
    title: 'About Us',
    items: [
      {
        path: '#!',
        label: 'Contact US',
      },
      {
        path: '#!',
        label: 'About Us',
      },
      {
        path: '#!',
        label: 'info@ainintel.com'
      },
    ],
  },
  {
    id: 2,
    title: 'Our Services',
    items: [
      {
        path: '#!',
        label: 'SEO Management ',
      },
      {
        path: '#!',
        label: 'Application Development',
      },
      {
        path: '#!',
        label: 'CRM Platform',
      },
    ],
  },
  {
    id: 3,
    title: 'Connect',
    items: [
      {
        path: 'https://www.facebook.com/AIN-Software-112850831322085/',
        icon: facebook,
        label: 'Facebook',
      },
      {
        path: 'https://twitter.com/ain_intel',
        icon: twitter,
        label: 'Twitter',
      },
      {
        path: 'https://github.com/AIN-LLC',
        icon: github,
        label: 'Github',
      },
    ],
  },
  {
    id: 4,
    title: 'Address',
    items: [
      {
        path: '#!',
        label: 'Address: 111 Winter Street, Hagerstown, Maryland 21740 USA'
      },
    ],
  },
 
];
