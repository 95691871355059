export default [
  {
    path: 'dev',
    label: 'App Development',
  },
  {
    path: 'seo',
    label: 'SEO Management',
  },
  {
    path: 'cms',
    label: 'CRM Platform',
  },
];
